<template>
    <div id="store-info">
        <section v-if="currentStore" class="store-info-section mb-5 mt-6 pt-5 pt-lg-5">
            <div class="container">
                <div class="row justify-content-center align-items-md-end align-self-center py-3">
                    <div v-if="!currentStore.onlyLead && currentStore.images && currentStore.images.logo" class="pb-4">
                        <img :src="currentStore.images.logo" class="mx-auto img-fluid store-main-logo" :alt="`${currentStore.companyName} logo`" />
                    </div>
                    <div class="pb-4" v-else>
                        <img src="@/assets/images/category/store-logo.svg" class="mx-auto img-fluid store-main-logo" :alt="`logo`" />
                    </div>
                    <div class="col-12">
                        <div class="w-100">
                            <div v-if="currentStore.metaFields" class="w-100 home-title text-left">
                                <span v-if="currentStore.positivityScore">{{ currentStore.positivityScore }} Score |</span> {{ currentStore.metaFields.h1 }}
                            </div>
                            <p v-if="currentStore.description" class="noto-sans-font-title text-left" v-html="parsedDescription"></p>
                        </div>

                        <p class="noto-sans-font-title text-left">
                            <span v-if="currentStore.positivityScore"> Positivity Score: {{ currentStore.positivityScore }} </span>
                            <br v-if="currentStore.positivityScore" />
                            <span v-if="currentStore.totalReviews"> Total Customer Feedback Reviewed: {{ currentStore.totalReviews }} </span>
                        </p>

                        <p class="noto-sans-font-title text-left" v-if="currentStore.phone">Store Phone Number: {{ currentStore.phone }}</p>

                        <div v-if="getSpecialStore.title" class="link-to-store">
                            <a :href="getSpecialStore.href" class="noto-sans-font-title text-left" target="_blank">
                                Read our full review of all {{ getSpecialStore.title }} locations here
                            </a>
                        </div>

                        <div class="noto-sans-font-title text-left">
                            Disclaimer: <br />
                            &nbsp;<router-link to="/">Willyou.net</router-link> is an independent platform providing factual, publicly available information
                            about jewelers. We are not affiliated with, endorsed by, or sponsored by the jeweler listed. Details are for informational purposes
                            only; please verify directly with the jeweler before making decisions. <router-link to="/">Willyou.net</router-link> is not
                            responsible for transactions or services provided by the jeweler.
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center align-self-center py-3">
                    <div class="col-md-6 col-sm-12">
                        <div class="w-100">
                            <div class="container px-0">
                                <div class="row">
                                    <div class="col pr-2 pr-md-3">
                                        <img
                                            class="mx-md-auto ml-lg-auto d-block img-fluid presentation-photo"
                                            :alt="currentStore.companyName"
                                            :src="currentStore.images && currentStore.images.photo2 ? currentStore.images.photo2 : `/images/stores/2.png`" />
                                    </div>
                                    <div class="col pl-2 pl-md-3">
                                        <img
                                            class="mx-md-auto ml-lg-auto d-block img-fluid presentation-photo"
                                            :alt="currentStore.companyName"
                                            :src="currentStore.images && currentStore.images.photo3 ? currentStore.images.photo3 : `/images/stores/3.png`" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <!-- <JewelerAi /> -->
                        <PositivityScore v-if="currentStore.positivityScore" :score="currentStore.positivityScore" />
                        <div v-if="false" class="w-100 text-left p-3 p-lg-0">
                            <div class="row">
                                <p class="noto-sans-14 col-12">
                                    <call @showPopup="showParentPopup()"></call>
                                </p>
                            </div>
                            <p class="noto-sans-14" v-if="currentStore.fullAddress">
                                <span class="font-weight-bold">Location: </span>
                                <span>{{ currentStore.fullAddress }}</span>
                            </p>
                            <p class="noto-sans-14">
                                <opening-hours-display headline="Store Hours" :hours-object="getHoursObject"></opening-hours-display>
                            </p>
                            <!--<router-link aria-label="start with a ring" :to="startWithRingLink" class="btn rnd-secondary-btn balance-width float-lg-left float-none mt-2 text-uppercase balance-width">Start with a ring</router-link>-->
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import OpeningHoursDisplayVue from "../../../components/OpeningHoursDisplay.vue";
import Call from "./Call.vue";
// import JewelerAi from "../../../components/store/JewelerAi.vue";
import PositivityScore from "../../../components/store/PositivityScore.vue";

export default {
    name: "store-info",
    components: {
        // 'map-component': MapComponent,
        "opening-hours-display": OpeningHoursDisplayVue,
        call: Call,
        // JewelerAi,
        PositivityScore,
    },
    computed: {
        ...mapState("willyou", ["currentStore"]),
        ...mapGetters("willyou", ["startWithRingLink"]),
        locations() {
            return this.currentStore && this.currentStore.companyName
                ? [
                      {
                          ...this.currentStore.location,
                          jewelerName: this.currentStore.companyName,
                      },
                  ]
                : [];
        },
        getHoursObject() {
            return this.currentStore.hours;
        },
        getTitle() {
            return this.currentStore && this.currentStore.metaFields && this.currentStore.metaFields.h1
                ? this.currentStore.metaFields.h1
                : this.currentStore.companyName;
        },
        getManagerImage() {
            return this.currentStore && this.currentStore.images && this.currentStore.images.manager
                ? this.currentStore.images.manager
                : require(`../../../../assets/images/store/blank-profile-image.svg`);
        },
        parsedDescription() {
            return this.currentStore.description
                ? this.currentStore.description
                      .replaceAll("{{{", '<b style="font-weight:600;">')
                      .replaceAll("}}}", "</b>")
                      .replace(/(?:\r\n|\r|\n)/g, "<br>")
                : "";
        },
        getSpecialStore() {
            const specialStore = { title: "", href: "/blog/reviews" };
            if (!this.currentStore || !this.currentStore.metaFields || !this.currentStore.metaFields.h1) return specialStore;
            const title = this.currentStore.metaFields.h1;
            //ZALES
            if (title.startsWith("Zales Jewelers")) {
                specialStore.href = "/blog/reviews/zales";
                specialStore.title = "Zales Jewelers";
            }
            //TIFFANY & CO
            else if (title.startsWith("Tiffany & Co")) {
                specialStore.href = "/blog/reviews/tiffany-and-co";
                specialStore.title = "Tiffany & Co";
            }
            //HELZBERG DIAMONDS
            else if (title.startsWith("Helzberg")) {
                specialStore.href = "/blog/reviews/helzberg";
                specialStore.title = "Helzberg Diamonds";
            }
            //KAY JEWELERS
            else if (title.startsWith("Kay Jewelers")) {
                specialStore.href = "/blog/reviews/kay-jewelers";
                specialStore.title = "Kay Jewelers";
            }
            //JARED
            else if (title.startsWith("Jared")) {
                specialStore.href = "/blog/reviews/jared-jewelers";
                specialStore.title = "Jared Galleria of Jewelry";
            }
            //FRED MEYER JEWELERS
            else if (title.startsWith("Fred Meyer")) {
                specialStore.href = "/blog/reviews/fred-meyer-jewelers";
                specialStore.title = "Fred Meyer Jewelers";
            }

            return specialStore;
        },
    },
    methods: {
        showParentPopup() {
            this.$emit("showPopup");
        },
    },
};
</script>

<style lang="scss" scoped>
#store-info {
    .presentation-photo {
        aspect-ratio: 1 / 1;
    }
    .map {
        height: 500px;
        margin: auto;
    }

    .meet-the-manager-h {
        font-family: "Noto Sans KR", sans-serif;
        font-size: 15px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4a4a4a;
    }

    .store-main-logo {
        max-width: 260px;
    }

    .link-to-store {
        color: #293887 !important;
        text-decoration: underline !important;
        margin: 12px 0;
        margin-bottom: 36px;
    }
    @media (min-width: 768px) {
        .store-info {
            padding-bottom: 80px;
        }

        .google-iframe {
            height: 450px;
            width: 100%;
        }
    }

    @media (max-width: 991px) {
        .store-info {
            padding-bottom: 50px;
        }

        .map {
            height: 400px;
        }

        .google-iframe {
            height: 450px;
            width: 100%;
        }
    }
}
</style>

<style lang="scss">
#store-info {
    .store-link {
        color: #293887 !important;
        text-decoration: underline !important;
    }
}
</style>
